module.exports = [{
      plugin: require('../.yarn/cache/gatsby-plugin-web-font-loader-npm-1.0.4-e6329b179e-7cb4df8ed5.zip/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"google":{"families":["Roboto:300,400,700"]}},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-image-virtual-2015e7bcf8/0/cache/gatsby-plugin-image-npm-1.12.0-b587a0a9e9-62cade4732.zip/node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-mdx-virtual-71b7458a95/0/cache/gatsby-plugin-mdx-npm-2.12.0-1d24ff2fba-ec4396d672.zip/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".md",".mdx"],"remarkPlugins":[null,[null,{"content":{"type":"element","tagName":"svg","properties":{"ariaHidden":true,"focusable":false,"width":16,"height":16,"viewBox":"0 0 16 16"},"children":[{"type":"element","tagName":"path","properties":{"fillRule":"evenodd","d":"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"}}]}}],null],"gatsbyRemarkPlugins":["gatsby-remark-check-links",{"resolve":"gatsby-remark-find-replace","options":{"replacements":{"ANDROID_VERSION":"11.5.2","ANDROID_TRAY_VERSION":"0.4.4"}}},{"resolve":"gatsby-remark-embed-video","options":{"urlOverrides":[{"id":"youtube"}]}},{"resolve":"gatsby-remark-responsive-iframe","options":{"wrapperStyle":"margin-bottom: 25px"}},{"resolve":"gatsby-remark-images","options":{"backgroundColor":"transparent","maxWidth":1175,"quality":80}},{"resolve":"gatsby-remark-prismjs","options":{"noInlineHighlight":true}},"gatsby-remark-copy-linked-files","gatsby-remark-smartypants","gatsby-remark-embedder"],"defaultLayouts":{},"lessBabel":false,"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/home/circleci/spokestack.io"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-twitter-virtual-fa47f78969/0/cache/gatsby-plugin-twitter-npm-3.12.0-306bbef6f3-e7cd2f39e7.zip/node_modules/gatsby-plugin-twitter/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-c26a27922b/0/cache/gatsby-plugin-google-tagmanager-npm-3.12.0-96e8ed3b23-5e1d4ff1e2.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WC6JHT4","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"route_change","enableWebVitalsTracking":false},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-canonical-urls-virtual-58b8641457/0/cache/gatsby-plugin-canonical-urls-npm-3.12.0-9baaf91756-7f53ee6491.zip/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.spokestack.io/"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-manifest-virtual-508387a140/0/cache/gatsby-plugin-manifest-npm-3.12.0-c28f336f9f-e5c6212d8b.zip/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"www.spokestack.io","start_url":"/","background_color":"#ffffff","theme_color":"#2f5bea","display":"minimal-ui","icon":"static/favicon.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"78eb74a337584505f95b5125030944bf"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-offline-virtual-e9a68f934c/0/cache/gatsby-plugin-offline-npm-4.12.0-7221307542-845db2b666.zip/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"dontCacheBustURLsMatching":{},"runtimeCaching":[{"urlPattern":{},"handler":"NetworkOnly"},{"urlPattern":{},"handler":"CacheFirst"},{"urlPattern":{},"handler":"NetworkFirst"},{"urlPattern":{},"handler":"StaleWhileRevalidate"}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
