// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-story-tsx": () => import("./../../../src/pages/about/story.tsx" /* webpackChunkName: "component---src-pages-about-story-tsx" */),
  "component---src-pages-about-team-tsx": () => import("./../../../src/pages/about/team.tsx" /* webpackChunkName: "component---src-pages-about-team-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tag-tutorial-tsx": () => import("./../../../src/pages/blog/tag/tutorial.tsx" /* webpackChunkName: "component---src-pages-blog-tag-tutorial-tsx" */),
  "component---src-pages-community-tsx": () => import("./../../../src/pages/community.tsx" /* webpackChunkName: "component---src-pages-community-tsx" */),
  "component---src-pages-contest-rules-tsx": () => import("./../../../src/pages/contest-rules.tsx" /* webpackChunkName: "component---src-pages-contest-rules-tsx" */),
  "component---src-pages-contribute-tsx": () => import("./../../../src/pages/contribute.tsx" /* webpackChunkName: "component---src-pages-contribute-tsx" */),
  "component---src-pages-docs-tsx": () => import("./../../../src/pages/docs.tsx" /* webpackChunkName: "component---src-pages-docs-tsx" */),
  "component---src-pages-features-asr-tsx": () => import("./../../../src/pages/features/asr.tsx" /* webpackChunkName: "component---src-pages-features-asr-tsx" */),
  "component---src-pages-features-keyword-tsx": () => import("./../../../src/pages/features/keyword.tsx" /* webpackChunkName: "component---src-pages-features-keyword-tsx" */),
  "component---src-pages-features-nlu-tsx": () => import("./../../../src/pages/features/nlu.tsx" /* webpackChunkName: "component---src-pages-features-nlu-tsx" */),
  "component---src-pages-features-speech-pipeline-tsx": () => import("./../../../src/pages/features/speech-pipeline.tsx" /* webpackChunkName: "component---src-pages-features-speech-pipeline-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-features-tts-tsx": () => import("./../../../src/pages/features/tts.tsx" /* webpackChunkName: "component---src-pages-features-tts-tsx" */),
  "component---src-pages-features-vad-tsx": () => import("./../../../src/pages/features/vad.tsx" /* webpackChunkName: "component---src-pages-features-vad-tsx" */),
  "component---src-pages-features-wake-word-tsx": () => import("./../../../src/pages/features/wake-word.tsx" /* webpackChunkName: "component---src-pages-features-wake-word-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-templates-blog-list-author-tsx": () => import("./../../../src/templates/blog-list-author.tsx" /* webpackChunkName: "component---src-templates-blog-list-author-tsx" */),
  "component---src-templates-blog-list-tag-tsx": () => import("./../../../src/templates/blog-list-tag.tsx" /* webpackChunkName: "component---src-templates-blog-list-tag-tsx" */),
  "component---src-templates-blog-list-tsx": () => import("./../../../src/templates/blog-list.tsx" /* webpackChunkName: "component---src-templates-blog-list-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-docs-page-tsx": () => import("./../../../src/templates/docs-page.tsx" /* webpackChunkName: "component---src-templates-docs-page-tsx" */),
  "component---src-templates-redirect-only-tsx": () => import("./../../../src/templates/redirect-only.tsx" /* webpackChunkName: "component---src-templates-redirect-only-tsx" */)
}

